import React from "react";
import CompanyLogo from "../../src/images/logo.webp";
import ScrollArrow from "./scroll-to-top-button";
import "./cookies-policy.css";
import Accessibility from "./AccessibilityWidget";

export default function PrivacyPolicy() {
  return (
    <div>
      <div>
        <div style={{ width: "100%", position: "relative" }}>
          <a href="/" className="link">
            <img
              src={CompanyLogo}
              alt="Company Logo"
              className="cookies-policy-company-logo"
            />
          </a>
          <ScrollArrow />
          <Accessibility />
        </div>

      </div>

      <div className="cookies-policy-content-1">
        <h1 style={{ paddingTop: "160px" }}>Cookies Policy</h1>

        <p>
          This Cookies Policy explains what Cookies are and how We use them. 
          You should read this policy so You can understand what type of cookies We use, 
          or the information We collect using Cookies and how that information is used.
        </p>

        <p>
          Cookies do not typically contain any information that personally identifies a user, 
          but personal information that we store about You may be linked to the information 
          stored in and obtained from Cookies. For further information on how We use, store and 
          keep your personal data secure, see our Privacy Policy.
        </p>

        <p>
          We do not store sensitive personal information, such as mailing addresses, 
          account passwords, etc. in the Cookies We use.
        </p>
      </div>

      <div className="cookies-policy-content">
        <h1>Interpretation and Definitions</h1>

        <h2>Interpretation</h2>

        <p>
          The words of which the initial letter is capitalized have meanings defined under 
          the following conditions. The following definitions shall have the same meaning 
          regardless of whether they appear in singular or in plural.
        </p>

        <h2>Definitions</h2>

        <p>For the purposes of this Cookies Policy:</p>

        <ul>
          <li>
            <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" 
            in this Cookies Policy) refers to BPPL Holdings PLC, Level 17, Access Towers, 278/4 Union Place, 
            Colombo 02, Sri Lanka.
          </li>
          <li>
            <strong>Cookies</strong> means small files that are placed on Your computer, 
            mobile device or any other device by a website, containing details of your 
            browsing history on that website among its many uses.
          </li>
          <li>
            <strong>Website</strong> refers to BPPL, accessible from{" "}
            <a href="https://www.bpplholdings.lk/" target="_blank" className="link">
              https://www.bpplholdings.lk/
            </a>
          </li>
          <li>
            <strong>You</strong> means the individual accessing or using the Website, 
            or a company, or any legal entity on behalf of which such individual is accessing 
            or using the Website, as applicable.
          </li>
        </ul>
      </div>

      {/* 03 */}
      <div className="cookies-policy-content">
        <h1>The use of the Cookies</h1>

        <h2>Type of Cookies We Use</h2>

        <p>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on 
          your personal computer or mobile device when You go offline, while Session Cookies 
          are deleted as soon as You close your web browser.
        </p>

        <p>
          We use both session and persistent Cookies for the purposes set out below:
        </p>

        <ul>
          <li>
            <p>
              <strong>Necessary / Essential Cookies</strong>
            </p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to provide You with services available 
              through the Website and to enable You to use some of its features. They help to 
              authenticate users and prevent fraudulent use of user accounts. Without these Cookies, 
              the services that You have asked for cannot be provided, and We only use these Cookies 
              to provide You with those services.
            </p>
          </li>
          <li>
            <p>
              <strong>Functionality Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies allow us to remember choices You make when You use the Website, 
              such as remembering your login details or language preference. The purpose of these Cookies 
              is to provide You with a more personal experience and to avoid You having to re-enter your 
              preferences every time You use the Website.
            </p>
          </li>
        </ul>
      </div>

      {/* 04 */}
      <div className="cookies-policy-content">
        <h1>Your Choices Regarding Cookies</h1>

        <p>
          If You prefer to avoid the use of Cookies on the Website, first You
          must disable the use of Cookies in your browser and then delete the
          Cookies saved in your browser associated with this website. You may
          use this option for preventing the use of Cookies at any time.
        </p>

        <p>
          If You do not accept Our Cookies, You may experience some inconvenience 
          in your use of the Website and some features may not function properly.
        </p>

        <p>
          If You'd like to delete Cookies or instruct your web browser to delete 
          or refuse Cookies, please visit the help pages of your web browser.
        </p>

        <ul>
          <li>
            <p>
              For the Chrome web browser, please visit this page from Google:
            </p>
            <a
              href="https://support.google.com/accounts/answer/32050"
              className="link"
              target="_blank"
            >
              https://support.google.com/accounts/answer/32050
            </a>
          </li>
          <li>
            <p>
              For the Internet Explorer web browser, please visit this page from
              Microsoft:
            </p>
            <a href="http://support.microsoft.com/kb/278835" target="_blank" className="link">
              http://support.microsoft.com/kb/278835
            </a>
          </li>
          <li>
            <p>
              For the Firefox web browser, please visit this page from Mozilla:
            </p>
            <a
              href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
              className="link"
              target="_blank"
            >
              https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
            </a>
          </li>
          <li>
            <p>
              For the Safari web browser, please visit this page from Apple:
            </p>
            <a
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              className="link"
              target="_blank"
            >
              https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            </a>
          </li>
        </ul>

        <p>
          For any other web browser, please visit your web browser's official
          web pages.
        </p>
      </div>

      {/* 05 */}
      <div className="cookies-policy-content">
        <h1>Contact Us</h1>

        <p>
          If you have any questions about this Cookies Policy, You can contact
          us:
        </p>

        <ul>
          <li>
            <p>
              By email:{" "}
              <a href="mailto:info@bpplholdings.com" className="link">
                info@bpplholdings.com 
              </a>
            </p>
          </li>
          <li>
            <p>
              By visiting this page on our website:{" "}
              <a className="link" target="_blank" href="https://www.bpplholdings.lk/contacts/">
                {" "}
                https://www.bpplholdings.lk/contacts/
              </a>
            </p>
          </li>
          <li>
            <p>
              By phone number:{" "}
              <a className="link" href="tel:+94112307170">
                {" "}
                (+94) 112 307 170
              </a>
            </p>
          </li>
        </ul>
      </div>
      <div style={{ marginTop: "100px" }} />
    </div>
  );
}
