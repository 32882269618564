import React from 'react';
import CookiesPolicy from "../components/cookies-policy";
import CookiesNotice from '../components/CookieNotice'

export default function contacts() {  
  return (
    <div>
      <CookiesPolicy />
      <CookiesNotice />
    </div>
  );
}
